import React from 'react'
import PlannerCard from '../components/PlannerCard'

function TourPlanner() {
  return (
    <div>
    <PlannerCard/>
    </div>
  )
}

export default TourPlanner;
