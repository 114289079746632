import React from 'react'
import FamouseCarouselData from './FamouseCarouselData';
import AliceCarousel from 'react-alice-carousel';


function FamouseCarousel() {
  const items = FamouseCarouselData.map((item) => (
    <img
      className="w-full h-96 cursor-pointer"
      role="presentation"
      src={item.image}
      alt="Offer Banner"
    />
  ));

  return (
    <div className="w-full h-96  ">
      <AliceCarousel
        items={items}
        autoPlay
        autoPlayInterval={3000}
        infinite
        disableButtonsControls
      />
    </div>
  )
}

export default FamouseCarousel