import React from 'react';

const CarPrice = ({ imageUrl, CarName, RentPrice, onButtonClick }) => {
  return (
    <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <a href="/">
        <img 
          className=" h-48 w-80 object-contain rounded-t-lg" 
          src={imageUrl} 
          alt={CarName} 
        />
      </a>
      <div className="px-5 pb-5">
        <a href="/">
          <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
            {CarName}
          </h5>
        </a>
        <div className="flex items-center mt-2.5 mb-5"></div>
        <div className="flex items-center justify-between">
          <span className="text-3xl font-bold text-gray-900 dark:text-white">
            {RentPrice}
          </span>
          <button
            onClick={onButtonClick}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarPrice;
