import React from 'react'

import TourCard from '../components/TourCard'
import Hero from '../components/Hero'

function TourPack() {
  return (
    <div>
        <Hero/>
        <TourCard/>
    </div>
  )
}

export default TourPack