import h1 from "../assets/h1.jpg";
import h2 from "../assets/h2.jpg";
import h3 from "../assets/h3.jpg";

const FamouseCarouselData = [
  {
    image: h1,
  },
  {
    image: h2,
  },
  {
    image: h3,
  },
];

export default FamouseCarouselData;