import React from 'react';
import I1 from "../assets/Ertiga.png"
import I2 from "../assets/Amaze.png"
import I3 from "../assets/Honda City.png"
import I4 from "../assets/Swift Desire.png"
import I5 from "../assets/Tempo Traveller.png"
import I6 from "../assets/Toyota Crysta.png"
import I7 from "../assets/Toyota Innova.png"
import CarPrice from './CarPrice';


const CarPriceValue = ()=>{
    const CarPriceValue =[
        {
            imageUrl:I1,
            CarName:"Ertiga",
            RentPrice:"₹13/Km",
            
        },
        {
            imageUrl:I2,
            CarName:"Amaze",
            RentPrice:"₹11/Km",
            
        },
        {
            imageUrl:I3,
            CarName:"Honda City",
            RentPrice:"₹16/Km",
            
        },
        {
            imageUrl:I4,
            CarName:"Swift Desire",
            RentPrice:"₹13/Km"
            
        },
        {
            imageUrl:I6,
            CarName:"Toyota Crysta",
            RentPrice:"₹17/Km"
            
        },
        {
            imageUrl:I7,
            CarName:"Toyota Innova",
            RentPrice:"₹14/Km"
            
        },
        {
            imageUrl:I5,
            CarName:"Tempo Traveller",
            RentPrice:"₹22/Km"
            
        }



    ]

    const contactUs = (CarPriceValue) => {
        console.log(CarPriceValue); // Should print the entire 'tour' object
        const phoneNumber = '+917232989905'; 
        const message = `I want to go with ${CarPriceValue.CarName}. Can you share further details.`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
      };

      return(
        <>

        <div className="h-20 w-[60%] bg-blue-500 mx-auto mt-5 relative shadow-lg rounded justify-center items-center justify-items-center flex">
        <p className="text-3xl font-bold">Call a taxi near me​</p>
      </div>

        <div className="container mx-auto p-4 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {CarPriceValue.map((CarPriceValue) => (
            <CarPrice
                CarName={CarPriceValue.CarName}
                imageUrl={CarPriceValue.imageUrl}
                RentPrice={CarPriceValue.RentPrice}
                onButtonClick={() =>{console.log(CarPriceValue.CarName,"iiii")
                    contactUs(CarPriceValue)}} // Passing the 'tour' object
            />
            ))}
        </div>
        
        </>
        

      )
      
}

export default CarPriceValue