export const TourPackageData = [
  {
    id: 1,
    title: "Varanasi Pilgrimage Tour Package",
    description: [
      "Day 1 : Varanasi Arrivals + Ganga Aarti",
      "Varanasi – Morning Boat Ride – Sarnath (15 KM 0.5 hr) – Temple Tour",
      "Day 3 : Varanasi Bodhgaya (300 Kms 6 Hrs)",
      "Day 4 : Bodhgaya – Gaya – Varanasi (300 Kms 6 Hrs)",
    ],
    imageUrl: "http://surl.li/vukvpz",
    price: "9,999 Only/-",
    button: "Book Now on Whatsapp",
  },
  {
    id: 2,
    title: "Varanasi Tour Package",
    description: [
      "Day 1 : Arrive Varanasi",
      "Day 2 :Varanasi – Allahabad – Varanasi",
      "Day 3 : Depart Varanasi",
    ],
    imageUrl: "http://surl.li/cxborj",
    price: "3,999 Only/-",
    button: "Book Now on Whatsapp",
  },
  {
    id: 3,
    title: "Varanasi Holiday Tour",
    description: [
      "Day 1 : Varanasi - Arrival + Ganga Arti",
      "Day 2 : Varanasi – Morning Boat Ride - Sarnath - Temple Tour",
      "Day 3 : Varanasi - Ayodhya (250KM – 5 hrs) Allahabad (200 KM 4 Hrs)",
      "Day 4 : Allahabad - Local tour to Triveni Sangam Varanasi",
    ],
    imageUrl: "http://surl.li/bblziy",
    price: "14,999 Only/-",
    button: "Book Now on Whatsapp",
  },
];

export const TourPlaceData = [
  {
    id: 1,
    title: "Ayodhya",
    Places: [
      "Ram Mandir",
      "Hanuman Garhi Mandir",
      "Dev Kali Mandir",
      "Saryu Asnan + Boat Riding",
      "Ram Ji ki Paudi",
      "Nagesvar Mandir",
    ],
    PlacePrice: ["1000", "500", "1000", "1500", "200", "200"],
    imageUrl: "http://surl.li/vukvpz",
    price: "3,999 Starting/-",
    button: "Plan Your Trip",
  },
];
