import React from "react";
import Call from "../assets/Phone.png"

const FloatingButtons = () => {
  const whatsappNumber = "+917232989905";
  const callNumber = "+917232989905";

  return (
    <>
      {/* WhatsApp Floating Button */}
      <a
        href={`https://wa.me/${whatsappNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-16 right-6 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
        title="Chat on WhatsApp"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
          className="w-6 h-6"
        />
      </a>

      {/* Call Floating Button */}
      <a
        href={`tel:${callNumber}`}
        className="fixed bottom-6 right-6 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
        title="Call Us"
      >
        <img
          src= {Call}
          alt="Call"
          className="w-6 h-6"
        />
      </a>
    </>
  );
};

export default FloatingButtons;
