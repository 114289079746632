import React from "react";

const Footer = () => {
  return (
    <footer className="bg-blue-800 text-white py-8">
      <div className="container mx-auto px-4">
        {/* Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* About Links */}
          <div>
            <h3 className="text-lg font-bold mb-4">Explore the World with Us</h3>
            <ul>
              <li><a href="/" className="hover:text-gray-400">About Us</a></li>
              <li><a href="/" className="hover:text-gray-400">Destinations</a></li>
              <li><a href="/" className="hover:text-gray-400">Gallery</a></li>
              <li><a href="/" className="hover:text-gray-400">Contact Us</a></li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div>
            <h3 className="text-lg font-bold mb-4">Subscribe to Our Newsletter</h3>
            <p className="mb-4">Stay updated with the latest travel deals and tips.</p>
            <form className="flex flex-col md:flex-row items-center md:items-start gap-2">
              <input
                type="email"
                placeholder="Email Address"
                className="px-4 py-2 rounded-lg text-black w-full md:w-auto"
              />
              <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg">
                Subscribe
              </button>
            </form>
          </div>

          {/* Contact Details */}
          <div>
            <h3 className="text-lg font-bold mb-4">Contact Us</h3>
            <p>📍 Kajjakpura, Varanasi</p>
            <p>📞 +91 7232989905</p>
            <p>✉️ sankalpdubey152002@gmail.com</p>
          </div>
        </div>

        {/* Social Links */}
        <div className="text-center mt-8">
          <h3 className="text-lg font-bold mb-4">Stay Connected</h3>
          <div className="flex justify-center space-x-4">
            <a href="/" className="hover:text-gray-400">Facebook</a>
            <a href="/" className="hover:text-gray-400">Instagram</a>
            <a href="/" className="hover:text-gray-400">Twitter</a>
            <a href="/" className="hover:text-gray-400">YouTube</a>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-700 mt-8 pt-4 text-center text-sm">
          <p>© 2024 cabkashi.in All Rights Reserved.</p>
          <div className="mt-2">
            <a href="/" className="hover:text-gray-400 mx-2">Terms of Service</a>|
            <a href="/" className="hover:text-gray-400 mx-2">Privacy Policy</a>|
            <a href="/" className="hover:text-gray-400 mx-2">FAQs</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
