import React from "react";
import "../styles.css"; // Assuming you have a stylesheet
import FamousCarousel from "./FamouseCarousel";

function Hero() {
  
  return (
    <div>
      <div className="relative">
        <FamousCarousel />
        <div className="absolute inset-0 bg-gradient-to-b from-blue-500 to-transparent"></div>
      </div>
    </div>
  );
}

export default Hero;
