import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function PlannerCard() {
  const location = useLocation();
  const tourPlaceData = location.state;

  
  const { Places = [], PlacePrice = [], price: defaultPrice = "0" } = tourPlaceData || {};

 
  const [selectedItems, setSelectedItems] = useState([]);

  
  const [showSelectedData, setShowSelectedData] = useState(false);

  
  const handleCheckboxChange = (index) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index); 
      } else {
        return [...prevSelected, index];
      }
    });
  };

 
  const handleUseSelectedData = () => {
    setShowSelectedData(true); 
  };

  
  const parsePrice = (price) => {
    const cleanedPrice = price.replace(/[^\d.-]/g, ""); 
    return parseFloat(cleanedPrice) || 0;
  };

  
  const selectedData = selectedItems.map((index) => ({
    place: Places[index],
    price: PlacePrice[index],
  }));

  const defaultPriceValue = parsePrice(defaultPrice); 

  const finalPrice = selectedData.reduce((acc, item) => acc + parsePrice(item.price), defaultPriceValue);

  return (
    <div className="m-4 p-4 items-center flex flex-col sm:flex-row sm:justify-between lg:flex-row lg:items-center max-w-screen-lg mx-auto gap-4">
      <div className="w-full max-w-screen-sm bg-white border border-gray-200 rounded-lg shadow dark:border-blue-500 p-3">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg">
          <thead>
            <tr className="dark:bg-blue-500">
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-100">
                Place
              </th>
              <th className="px-6 py-3 text-center text-sm font-medium text-gray-100">
                Price
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-100">
                Select
              </th>
            </tr>
          </thead>
          <tbody>
            {Places.map((place, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } hover:bg-gray-100`}
              >
                <td className="px-6 py-4 text-sm text-gray-900">{place}</td>
                <td className="px-6 py-4 text-sm text-gray-900">
                  {PlacePrice[index] || "N/A"}
                </td>
                <td className="px-6 py-4 text-center">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(index)}
                    onChange={() => handleCheckboxChange(index)}
                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          onClick={handleUseSelectedData}
          className="mt-4 px-4 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-600"
        >
          Get The Final Price
        </button>
      </div>

      {/* Div to show selected data */}
      {showSelectedData && (
        <div className="mt-6 w-full max-w-screen-sm bg-white border border-gray-200 rounded-lg shadow dark:border-blue-500 p-3">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="dark:bg-blue-500">
                <th className="px-6 py-3 text-left text-sm font-medium text-gray-100">
                  Place
                </th>
                <th className="px-6 py-3 text-center text-sm font-medium text-gray-100">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedData.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="px-6 py-4 text-sm text-gray-900">{item.place}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{item.price}</td>
                </tr>
              ))}
            </tbody>
            
            <tfoot>
              <tr className="dark:bg-blue-500">
                <td className="px-6 py-3 text-sm font-medium text-gray-100">Final Price</td>
                <td className="px-6 py-3 text-center text-sm font-medium text-gray-100">
                  {finalPrice.toFixed(2)} /-
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="parent">
            <div className=""></div>
            <div></div>
            </div>
        </div>
      )}
    </div>
  );
}

export default PlannerCard;
