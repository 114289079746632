import React from "react";
import Card from "./Card";
import { TourPackageData, TourPlaceData } from "./TourPlaceData";
import { useNavigate } from "react-router-dom";

const TourCard = () => {
  const handleBooking = (TourPackageData) => {
    console.log(TourPackageData); // Should print the entire 'tour' object
    const phoneNumber = "+917232989905";
    const message = `I want to go with: ${TourPackageData.title}. Can you share further details.`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  const navigate = useNavigate();

  const handleTourBooking = (placeData) => {
    console.log(placeData, "iiii"); 
    navigate("/TourPlanner", { state: placeData });
    
  };
  
  return (
    <>
      <div className="h-20 w-[60%] bg-blue-500 mx-auto mt-5 relative shadow-lg rounded justify-center items-center justify-items-center flex">
        <p className="text-3xl font-bold">OUR TRENDING PACKAGES​</p>
      </div>

      <div className="container mx-auto p-4 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {TourPackageData.map((TourPackageData) => (
          <Card
            key={TourPackageData.id}
            title={TourPackageData.title}
            description={TourPackageData.description}
            imageUrl={TourPackageData.imageUrl}
            price={TourPackageData.price}
            button={TourPackageData.button}
            onButtonClick={() => {
              console.log(TourPackageData.title, "iiii");
              handleBooking(TourPackageData);
            }} // Passing the 'tour' object
          />
        ))}
      </div>

      <div className="h-20 w-[60%] bg-blue-500 mx-auto mt-5 relative shadow-lg rounded justify-center items-center flex">
        <p className="text-3xl font-bold">Tour By Location​</p>
      </div>

      <div className="container mx-auto p-4 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {TourPlaceData.map((placeData) => (
        <Card
        key={placeData.id}
        title={placeData.title}
        Places={placeData.Places} // Ensure this exists
        imageUrl={placeData.imageUrl}
        price={placeData.price}
        button={placeData.button}
        onButtonClick={() => {
          
          handleTourBooking(placeData); // Pass the correct `placeData`
        }}
      />
      
      ))}

      </div>
    </>
  );
};

export default TourCard;
