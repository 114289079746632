import React from "react";


const Card = ({ title, description = [], imageUrl, price, onButtonClick, button, Places = [] }) => {
  return (
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
      <img className="w-full h-48 object-cover" src={imageUrl} alt={title} />
      <div className="p-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">{title}</h2>
        {description && description.length > 0 && (
          <ul className="list-disc list-inside text-gray-600 text-sm mb-4 object-contain h-36">
            {description.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        )}
        {Places && Places.length > 0 && (
          <ul className="list-disc list-inside text-gray-600 text-sm mb-4 object-contain h-36">
            {Places.map((place, index) => (
              <li key={index}>{place}</li>
            ))}
          </ul>
        )}

        <div className="p-4 flex justify-between items-center border-t border-gray-200 mt-auto">
          <span className="text-lg font-semibold text-blue-600">{price}</span>
          <button
            onClick={onButtonClick}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors"
          >
            {button}
          </button>
        </div>
      </div>
    </div>
  );
};


export default Card;
