import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaShop, FaPlay, FaServicestack } from "react-icons/fa6";

import { SectionContainer } from "./SectionContainer";


function Navbar() {
  const [selectedNav, setSelectedNav] = useState("home");

  const navItems = [
    { name: "home", label: "Home", icon: <FaShop size={20} />, path: "/" },
    {
      name: "pricing",
      label: "Pricing",
      icon: <FaPlay size={20} />,
      path: "/pricing",
    },
    {
      name: "package",
      label: "Package",
      icon: <FaServicestack size={20} />,
      path: "/package",
    },
  ];
  
  return (
   <div className="bg-blue-500 sticky top-0 z-50">
     <SectionContainer className="flex justify-between ">
     <div>
     <img
        className="h-16 w-40 object-cover"
        alt="img"
        src={require("../assets/logo.png")}
      />
     </div>
      <div className="flex gap-5">
        {navItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.path}
            onClick={() => setSelectedNav(item.name)}
            className={`flex gap-3 items-center justify-center cursor-pointer text-white ${
              selectedNav === item.name ? "border-b-2" : ""
            }`}
            // style={({ isActive }) => ({
            //   color: isActive ? "blue" : "white",
            // })}
          >
            {/* {React.cloneElement(item.icon, {
              color: selectedNav === item.name ? "purple" : "gray",
            })} */}
            <p>{item.label}</p>
          </NavLink>
        ))}
      </div>
    </SectionContainer>
   </div>
  );
}

export default Navbar;
