import React from 'react'
import Hero from '../components/Hero'
import CarPrice from '../components/CarPriceValue';


function Home() {
  return (
    <>
    <div>
        <Hero/>
        <CarPrice/>
    </div>
    <div>
      
    </div>
    </>
  );
  
};

export default Home