import "./App.css";
import "./styles.css";
import Navbar from "./components/Navbar";
import Fab from "../src/components/Fab"



import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import TourPack from "./pages/TourPack";
import Footer from "./components/Footer";
import CarPriceValue from "./components/CarPriceValue";
import TourPlanner from "./pages/TourPlanner";

function App() {
  return (
    
    <>
    
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/package" element={<TourPack/>}/>
        <Route path="/pricing" element={<CarPriceValue/>}/>
        <Route path="/TourPlanner" element={<TourPlanner/>}/>
      </Routes>
      <div>
      <Fab/>
      </div>
      <Footer/>
    </>
  );
}

export default App;
